import {types} from "../actions/auth";

const INITIAL_STATE = {
    isAuthenticated: false
};

export default function auth(state = INITIAL_STATE, action) {
    const { type, payload } = action;

    switch (type) {
        case types.LOGIN_SUCCESS:
            return { ...state, ...payload, isAuthenticated: true };

        case types.LOGOUT_SUCCESS:
            return INITIAL_STATE;

        default:
            return state;
    }
}
