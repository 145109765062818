import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {Provider} from "react-redux";
import configureStore from "./store/configureStore";
import {BrowserRouter as Router} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

const store = configureStore();

const rootElement = document.getElementById("root");

const Root = () => (<Provider store={store}>
    <Router
        //basename="/pwa"
    >
        <React.StrictMode>
            <Suspense fallback="loading">
                <App />
            </Suspense>
        </React.StrictMode>
    </Router>
</Provider>);

if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(<Root />, rootElement);
} else {
    ReactDOM.render(<Root />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
