import AuthActions from "../store/actions/auth";
import {Link} from "react-router-dom";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import AuthSelectors from "../store/selectors/auth";
import UserSelectors from "../store/selectors/user";


const Navbar = () => {

    const isAuthenticated = useSelector(state => AuthSelectors.isAuthenticated(state));
    const user = useSelector(state => UserSelectors.getUser(state));

    const dispatch = useDispatch();

    const onExitPress = () => dispatch(AuthActions.logout());

    return (
        <nav>
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>

                <div style={{ float: 'right' }}>
                    { isAuthenticated ? (
                        <>
                            <li>
                                <Link to={`/profile`}>Profilo</Link>
                            </li>
                            <li>
                                <button onClick={onExitPress}>Esci</button>
                            </li>
                        </>
                    ): (
                        <li>
                            <Link to="/login">Login</Link>
                        </li>
                    ) }
                </div>

            </ul>
        </nav>
    );
}

export default Navbar;