import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'

import monitorReducersEnhancer from './enhancers/monitorReducers'
import loggerMiddleware from './middleware/logger'
import combinedReducers from './reducers/combinedReducers'

export default function configureStore(preloadedState) {
    const middlewares = [thunkMiddleware]
    if (process.env.NODE_ENV === 'development') {
        middlewares.push(loggerMiddleware)
    }
    const middlewareEnhancer = applyMiddleware(...middlewares)

    const enhancers = [middlewareEnhancer, monitorReducersEnhancer];

    const composeEnhancers =
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
            }) : compose;

    const composedEnhancers = composeEnhancers(...enhancers)

    const store = createStore(combinedReducers, composedEnhancers);

    return store;
}