import React from "react";
import {Redirect, Route, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import AuthSelectors from "./store/selectors/auth";

const PrivateRoute = ({ children, ...rest }) => {
    const location = useLocation();
    const isAuthenticated = useSelector(state => AuthSelectors.isAuthenticated(state));

    return (
        <Route {...rest}>
            { isAuthenticated ?
                (children)
                :
                <Redirect to={{ pathname: "/login", state: { from: location } }} />
            }
        </Route>
    );
};

export default PrivateRoute;