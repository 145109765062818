import axios from "axios";
import env from "react-dotenv";

class NetworkService {

    init() {
        axios.defaults.baseURL = env.SERVER_BASE_PATH;

        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.defaults.timeout = 30000;
        axios.defaults.withCredentials = 'include';

        //axios.interceptors.request.use(function (config) {
        //    console.info('will sent request')
        //    // Do something before request is sent
        //    return config;
        //}, function (error) {
        //    // Do something with request error
        //    return Promise.reject(error);
        //});

        //axios.interceptors.response.use(function (response) {
        //    // Any status code that lie within the range of 2xx cause this function to trigger
        //    // Do something with response data
        //    console.info('we have response', response);
        //    return response;
        //}, function (error) {
        //    // Any status codes that falls outside the range of 2xx cause this function to trigger
        //    // Do something with response error
        //    return Promise.reject(error);
        //});

    }

}

export default new NetworkService();

