import axios from "axios";
import endpoints from './endpoints.json';

export const login = async (
    email: string,
    password: string
) => axios({
    method: 'POST',
    url: endpoints.auth.login,
    data: {
        //username: email,
        email,
        password,
        //grant_type: 'password',
        //client_id: 3,
        //client_secret: 'm798LPL9ZSFkXhm9ZKeJxjTWGcgcvbFfcmKRIPlF'
    }
})

export const signin = async (
    email: string,
    password: string
) => axios({
    method: 'POST',
    url: endpoints.auth.registration,
    data: {
        email,
        password,
        "provider": "",
        "socialToken": "",
        "tokenICloud": "",
        "birthDate": "2021-01-15T17:28:11.587Z",
        "name": "prova",
        "surname": "deepcube",
        "cap": 66100,
        "phoneNumber": "+393339700806",
        "privacyPersonalData": false,
        "privacyMarketing": false,
        "privacyNewsLetter": false,
        "profiling": true,
        "marketingPostAddress": "Fara",
        "marketingPostCity": "15042",
        "marketingPostNumber": "27",
        "deviceUID": "7f573605d787617f",
        "appVersion": "2.8.9",
        "platform": "android",
        "privacyMarketingExternalBrands": true,
        "profilingExternalBrands": false,
        "birthDateResources": "lastCycle",
        "birthDateSourceDate": "2021-01-15T17:28:11.587Z"
    }
})

export const logout = async () => axios({
    method: 'POST',
    url: endpoints.auth.logout,
})