import {types} from "../actions/user";

const INITIAL_STATE = { };

export default function user(state = INITIAL_STATE, action) {
    const { type,  payload  } = action;

    switch (type) {
        case types.RESET:
            return INITIAL_STATE;

        case types.SET_USER_DATA:
            return { ...state, ...payload };

        default:
            return state;
    }
}
