import {login, logout, signin} from "../../services/network/auth.api";
import {types as UserActions} from "./user";

export const types = {
    LOGIN_REQUEST: '[Auth] LOGIN_REQUEST',
    LOGIN_SUCCESS: '[Auth] LOGIN_SUCCESS',
    LOGIN_ERROR: '[Auth] LOGIN_ERROR',
    SIGN_IN_REQUEST: '[Auth] SIGN_IN_REQUEST',
    SIGN_IN_SUCCESS: '[Auth] SIGN_IN_SUCCESS',
    SIGN_IN_ERROR: '[Auth] SIGN_IN_ERROR',
    LOGOUT_REQUEST: '[Auth] LOGOUT_REQUEST',
    LOGOUT_SUCCESS: '[Auth] LOGOUT_SUCCESS',
    LOGOUT_ERROR: '[Auth] LOGOUT_ERROR',
}

const AuthActions = {

    login: ({ email, password }) => async (dispatch) => {

        dispatch({ type: types.LOGIN_REQUEST });

        try {

            const response = await login(email, password);

            const { apiToken, tokenDeadline, bootData: { user } } = response.data.data;

            dispatch({ type: types.LOGIN_SUCCESS, payload: { apiToken, tokenDeadline } });
            dispatch({ type: UserActions.SET_USER_DATA, payload: { ...user } });

            return true;

        } catch (e) {
            dispatch({ type: types.LOGIN_ERROR, payload: { errorMessage: e.message } });

            throw e;
        }

    },

    signin: ({ email, password }) => async (dispatch) => {

        dispatch({ type: types.SIGN_IN_REQUEST });

        try {

            const response = await signin(email, password);

            dispatch({ type: types.SIGN_IN_SUCCESS, payload: response.data })

        } catch (e) {
            dispatch({ type: types.SIGN_IN_ERROR });

        }

    },


    logout: () => async (dispatch) => {

        dispatch({ type: types.LOGOUT_REQUEST });

        try {

            // temp
            dispatch({ type: types.LOGOUT_SUCCESS })
            return;

            const response = await logout();

            dispatch({ type: types.LOGOUT_SUCCESS })

        } catch (e) {
            dispatch({ type: types.LOGOUT_ERROR });

        }

    }

}

export default AuthActions;