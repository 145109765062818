import React, {useEffect} from 'react';
import {Route, Switch,} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import './App.css';
import NetworkService from './services/network';
import './i18n';

import Navbar from "./components/Navbar";

const SigninPage = React.lazy(() => import("./pages/SigninPage"));
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const ProfilePage = React.lazy(() => import("./pages/ProfilePage"));
const HomePage = React.lazy(() => import("./pages/HomePage"));

/**
 * Initialize network service to set default configs
 */
NetworkService.init();

function App() {

    useEffect(() =>  {

    }, []);

    return (
        <div className="App">

            <Navbar />

            <Switch>
                <Route path="/login">
                    <LoginPage />
                </Route>
                <Route path="/signin">
                    <SigninPage />
                </Route>
                <PrivateRoute path="/profile">
                    <ProfilePage />
                </PrivateRoute>
                <Route path="/">
                    <HomePage />
                </Route>

            </Switch>

        </div>
    );
}

export default App;
